html:not(html[light]) {
    --dark-stage-1: #121316;
    --dark-stage-2: #26292f;
    --text-color: #ccc;
    --text-color-alt: #888;

    --site-main-color: greenyellow;
    --site-code-color: #9ecaf9;
    --site-code-background: rgba(0, 0, 0, 0.2);

    --type-release: greenyellow;
    --type-prerelease: hsl(45deg 100% 59%);
    --type-draft: hsl(0deg 100% 73%);

    --release-hover-background: #444
}

body {

    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

    color: var(--text-color, lightgray);
    background: var(--dark-stage-1, #222);
}

header {
    max-width: 80rem;

    margin: 10px auto 15px auto;
}

header nav {
    display: grid;
    grid-template: 1fr / auto auto;
    align-items: center;
}

header nav h1 {
    display: inline-block;
}

header nav h1 a {
    color: var(--site-main-color, greenyellow);
    text-decoration: none;
}

header nav ul {
    justify-self: end
}

header nav li {
    display: inline-block;

    padding: 0 8px;
}

header nav li a {
    color: var(--text-color, #cccccc);
    text-decoration: none;

    transition: opacity .5s ease-in-out,filter .2s ease-in-out,transform .05s ease-in-out,outline .2s ease-in-out, color 0.2s ease-out;
}

header nav li:active {
    transform: scale(0.95);
}

header nav li:hover > a {
    color: var(--site-main-color, greenyellow);
}

main {
    display: grid;
    column-gap: 0.75rem;
    grid-template:
        "sidebar content" auto
        "info content" auto
        "dummy content" 1fr/20rem 1fr;
    margin: 0 auto;
    max-width: 80rem;

    /*display: grid;*/
    /*grid-template:*/
    /*    "sidebar"*/
    /*    "content"*/
    /*    "info"/100%;*/
    padding: 0 0.75rem;
}

main > aside > div:not(:empty) {
    grid-area: sidebar;
    grid-row: 3;

    border-radius: 10px;
    background-color: var(--dark-stage-2, #333);
    box-shadow: 5px 10px 15px -3px rgba(0,0,0,0.1);

    height: fit-content;
}

main aside > div > div {
    padding: 0.9rem;
}

main aside div .mod-header {
    max-width: 100%;
    border-radius: 10px 10px 0 0;
}

main > aside > div:not(:last-child) {
    margin-bottom: 10px
}

main > section {
    grid-area: content;
    max-width: 59.25rem;
}

main > section > div {
    display: flex;
    flex-direction: column;

    gap: 0.75rem;
}

main > section > div:not(#mods):not(:empty), #mods > .card:not(:empty) {
    background: var(--dark-stage-2, #333);
    padding: 10px 20px;
    border-radius: 10px;

    box-shadow: 5px 10px 15px -3px rgba(0,0,0,0.1);
}

main > section > div:not(:last-child) {
    margin-bottom: 10px;
}

#mods > .card {
    display: grid;

    column-gap: 0.75rem;
    grid-template: 1fr / 96px auto 15%;
    align-items: center;
}

img.mod-image {
    border-radius: 10px;
    width: 96px;
    height: 96px;
}

#sidebar .mod-image {
    margin-top: -150px;
    margin-left: 5px;
    background: var(--dark-stage-2, #333);
    border: var(--dark-stage-2, #333) 4px solid;
}

#mods > .card .tags > span {
    margin-right: 5px;
    display: inline-block;
}

#mods > .card > div:last-child {
    font-size: 0.9rem;
}

footer {

}

label {
    font-size: 0.8rem;
    line-height: 0.8;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

label:not(:last-child) {
    margin-bottom: 5px;
}


input[type=checkbox] {
    appearance: none;
    background-color: #555;
    margin: 0;
    color: currentColor;
    width: 1em;
    height: 1em;
    border-radius: 0.3em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: center;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em black;
}

input[type=checkbox]:checked {
    background: var(--site-main-color);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

a {
    color: var(--text-color, lightgray);
    text-decoration: underline;
}

a:hover {
    color: var(--site-main-color);
}

ul {
    margin: 0;
    padding: 0;
}

hr {
    border: 0;
    background: rgba(255, 255, 255, 0.6);
    height: 1px;
    margin: 10px 0;
}

p {
    margin: 10px 0;
}

.inline {
    display: inline-block;
}

@media screen and (max-width: 1024px) {
    main {
        display: block;
    }

    main > aside {
        display: none
    }
}
